import rugbyIcon from '../assets/images/sportsbook/icons/rugby.png';
import iceHockeyIcon from '../assets/images/sportsbook/icons/ice-hockey.png';
import badmintonIcon from '../assets/images/sportsbook/icons/badminton.png';
import tableTennisIcon from '../assets/images/sportsbook/icons/table-tennis.png';
import GreyhoundIcon from '../assets/images/sportsbook/icons/greyhound-live.png';
import HorseRacing from '../assets/images/sportsbook/icons/horse_racing.png';

export const EXCHANGE_EVENT_TYPES = [
  { id: '4', name: 'Cricket',langkey:'cricket', slug: 'cricket' },
  { id: '1', name: 'Football',langkey:'football', slug: 'football' },
  { id: '2', name: 'Tennis',langkey:'tennis', slug: 'tennis' },
  { id: '7', name: 'Horse Racing',langkey:'horse_racing', slug: 'horseracing' },
  {
    id: '4339',
    name: 'GreyHound',
    langkey:'greyhound',
    slug: 'greyhound',
  },
  {
    id: '7522',
    name: 'basketball',
    langkey:'basketball',
    slug: 'basketball',
    enabled: true,
  },
  {
    id: 'sr_sport_4',
    name: 'Ice Hockey',
    langKey:'ice_hockey',
    slug: 'icehockey',
    enabled: true,
  },
  {
    id: 'sr_sport_20',
    name: 'Table Tennis',
    langKey:'table_tennis',
    slug: 'tabletennis',
    enabled: true,
  },
  {
    id: '7511',
    name: 'baseball',
    langkey:'baseball',
    slug: 'baseball',
    enabled: true,
  },
];

export const SPORTS_MAP = new Map([
  [
    'Cricket',
    { id: '4', name: 'Cricket',langkey:'cricket', slug: 'cricket', priority: 0, disable: false },
  ],
  [
    'Football',
    {
      id: '1',
      name: 'Football',
      langkey:'football',
      slug: 'football',
      priority: 1,
      disable: false,
    },
  ],
  [
    'Tennis',
    { id: '2', name: 'Tennis',langkey:'tennis', slug: 'tennis', priority: 2, disable: false },
  ],
  [
    'Horse Racing',
    {
      id: '7',
      name: 'Horse Racing',
      langkey:'horse_racing', 
      slug: 'horseracing',
      priority: 3,
      disable: false,
    },
  ],
  [
    'GreyHound',
    {
      id: '4339',
      name: 'GreyHound',
      langkey:'greyhound',
      slug: 'greyhound',
      priority: 4,
      disable: false,
    },
  ],
  [
    'Basketball',
    {
      id: '7522',
      name: 'basketball',
      langkey:'basketball',
      slug: 'basketball',
      enabled: true,
      priority: 5,
      disable: false,
    },
  ],
  [
    'Baseball',
    {
      id: '7511',
      name: 'baseball',
      langkey:'baseball',
      slug: 'baseball',
      enabled: true,
      priority: 6,
      disable: false,
    },
  ],
]);

export const EXCH_SPORTS_MAP = {
  tennis: '2',
  football: '1',
  soccer: '1',
  cricket: '4',
  horseracing: '7',
  greyhound: '4339',
};

type oEvent = {
  label: string;
  img: string;
};

export const OTHER_EVENTS: oEvent[] = [
  // { label: 'Greyhound', img: Greyhound },
  // { label: 'Horse Racing', img: HorseRacing },
  // { label: 'Rugby', img: rugbyIcon },
  // { label: 'Table Tennis', img: tableTennisIcon },
  // { label: 'Ice Hockey', img: iceHockeyIcon },
  { label: 'Badminton', img: badmintonIcon },
];
