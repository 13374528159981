import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { Redirect, Switch } from 'react-router';
import { connect } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { ToastContainer } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import defaultLangData from '../src/locales/default-lang.json'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.scss';
import { RootState } from './models/RootState';

import AcceptTerms from './pages/AcceptTerms/AcceptTerms';
import { fetchMailVerifiedSuccess, logout, setDomainConfig } from './store';
import AccessRedirect from './pages/AccessRedirect/Accessredirect';
import { setTimeout } from 'timers';
import LoadingPage from './pages/LoadingPage/LoadingPage';
import { HelmetProvider } from 'react-helmet-async';
import WelcomeBonus from './pages/WelcomeBonus/WelcomeBonus';
import { ShowWelcomePage } from './constants/WhitelabelConfig';
import { BRAND_DOMAIN, BRAND_NAME } from './constants/Branding';
import { fetchContentConfig, setAlertMsg, setLangData, setLangSelected, setLanguages } from './store/common/commonActions';
import SVLS_API from './svls-api';
import { DomainConfig } from './models/DomainConfig';
import { defaultLang, getLang, getLangCode, getSelectedLang, getUpdatedSelectedLang } from './util/localizationUtil';
import LANG_API from './models/api/language-api';
import { AlertDTO } from './models/Alert';
import { themesList } from './util/stringUtil';

const MainPage = lazy(() => import('./router/UserRouter'));
const ResetPassword = lazy(
  () => import('./pages/ResetPasswordPage/ResetPasswordPage')
);
const LoginPage = lazy(() => import('./pages/Login/LoginPage'));
const SignUp = lazy(() => import('./pages/SignUp/SignUp'));
const ForgotPwdForm = lazy(
  () => import('./components/ForgotPassword/ForgotPassword')
);
const ThankYou = lazy(() => import('./pages/ThankYou/ThankYou'));

type StateProps = {
  fetchMailVerifiedSuccess: (mailVerified: boolean) => void;
  logout: () => void;
  mailVerified: boolean;
  prefersDark: string;
  loggedIn: boolean;
  fetchContentConfig: any;
  setDomainConfig: (config: DomainConfig) => void;
  setLanguages: (languages: string[]) => void;
  setLangSelected: (lang: string) => void;
  setLangData: (langData: any) => void;
  langData: string;
  setAlertMsg: Function;
};

const App: React.FC<StateProps> = (props) => {
  const { setDomainConfig } = props;
  const consoleOpen = useConsoleOpen();
  const helmetContext = {};
  // const consoleOpen = false;

  const { logout, prefersDark, loggedIn, fetchContentConfig, setLanguages, setLangSelected, setLangData, langData,
    setAlertMsg, } = props;
  const lang = getLang(sessionStorage?.getItem('lang'));

  useEffect(() => {
    if (BRAND_NAME?.toLocaleLowerCase() === 'faircric') {
      document.head.insertAdjacentHTML(
        'beforeend',
        '<meta name="facebook-domain-verification" content="vdgdeddqhj79c9wx1yntfu8b4fk3bw" />'
      );
    } else if (BRAND_NAME?.toLocaleLowerCase() === 'betex247') {
      document.head.insertAdjacentHTML(
        'beforeend',
        '<meta name="facebook-domain-verification" content="h9fvha0bdwxdnb93w4yd1iphnyfwst" />'
      );
    }
  }, []);

  useEffect(() => {
    const toggleTheme = (theme) => {
      if (theme === 'dark') {
        document.body.setAttribute('data-theme', 'orange');
      } else if (theme === 'light') {
        document.body.setAttribute('data-theme', 'yellow');
      } else if (theme === 'green') {
        document.body.setAttribute('data-theme', 'green');
      } else if (theme === 'purple') {
        document.body.setAttribute('data-theme', 'purple');
      } else if (theme === 'blue') {
        document.body.setAttribute('data-theme', 'dgreen');
      } else if (theme === 'orange') {
        document.body.setAttribute('data-theme', 'orange');
      } else if (theme === 'pink') {
        document.body.setAttribute('data-theme', 'pink');
      } else if (theme === 'pink1') {
        document.body.setAttribute('data-theme', 'pink1');
      } else if (theme === 'green1') {
        document.body.setAttribute('data-theme', 'green1');
      } else if (theme === 'brown') {
        document.body.setAttribute('data-theme', 'brown');
      }
    };

    let systemDark = window.matchMedia(
      `(prefers-color-scheme: ${prefersDark})`
    );
    systemDark.addListener((e) => toggleTheme(prefersDark));
    let a = [...themesList];
    a.splice(a.indexOf(prefersDark), 1);
    document.body.classList.remove(...a);
    document.body.classList.toggle(prefersDark, true);
  }, [prefersDark]);

  // useEffect(() => {
  //   const selectedLang = sessionStorage.getItem("lang")
  //     ? sessionStorage.getItem("lang")
  //     : "en";
  //   dispatch(getLangData(selectedLang));
  // }, []);
  const handleOnIdle = () => {
    console.log('user is idle');
    if (loggedIn) {
      logout();
    }
  };

  const handleClose = () => {
    console.log('user open dev tools');
  };

  useIdleTimer({
    timeout: 1000 * 60 * 60 * 2, // 2 hours
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useEffect(() => {
    fetchContentConfig();
  }, []);

  useEffect(() => {
    getDomainConfig();
  }, [loggedIn]);
  const afterProcess = (languages: string[]) => {
    var sessionLang = sessionStorage.getItem('lang');
    var lang = sessionLang
      ? getUpdatedSelectedLang(languages, sessionLang)
      : getSelectedLang(languages);

    sessionStorage.setItem('lang', lang);
    setLangSelected(sessionStorage.getItem('lang'));
  };

  const getDomainConfig = async () => {
    try {
      const response = await SVLS_API.get(`/account/v2/books/${BRAND_DOMAIN}`);
      if (response.status === 200) {
        var languages = response?.data?.languages;
        setLanguages(languages);
        const dConfig: DomainConfig = {
          demoUser: response.data.demo_user_enabled,
          signup: response.data.signup_enabled,
          whatsapp: response.data.whatsapp_support,
          payments: response.data.payments_enabled,
          bonus: response.data.bonus_enabled,
          casinoEnabled: response.data.casino_enabled,
          sportsEnabled: response.data.sports_enabled,
          isDomainConfigLoading: false
        };
        setDomainConfig(dConfig);
      }
    } catch (err) {
      console.log(err);
    }
    finally {
      afterProcess(languages);
    }
  };


  useEffect(() => {
    if (lang) {
      getLangData();
    }
  }, [lang]);

  const getLangData = async () => {
    try {
      var langCode = getLangCode(lang)?.toLowerCase();
      if (langCode === 'en') {
        sessionStorage.setItem('lang', defaultLang);
        setLangSelected(defaultLang);
        setLangData(defaultLangData);
        return;
      }
      const response = await LANG_API.get(`/languages/${langCode}/lang.json`);
      const data = await response.data;
      if (data) {
        setLangData(data);
      } else {
        setDefaultLangData();
      }
    } catch (error) {
      console.error('Error getting language data:', error);
      setDefaultLangData();
    }
  };

  const setDefaultLangData = () => {
    setAlertMsg({
      type: 'error',
      message: 'Language not supported.',
    });
    sessionStorage.setItem('lang', defaultLang);
    setLangSelected(defaultLang);
    setLangData(defaultLangData);
  };

  return (
    <>
      <HelmetProvider context={helmetContext}>
        {!ShowWelcomePage[BRAND_DOMAIN] ? (
          <>
            {!consoleOpen ? (
              <Suspense fallback={<LoadingPage />}>
                <BrowserRouter>
                  <Switch>
                    <Route path="/access-redirect" component={AccessRedirect} />
                    <Route
                      path="/terms-and-conditions"
                      component={AcceptTerms}
                    />
                    <Route path="/reset-password" component={ResetPassword} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/register" component={SignUp} />
                    <Route path="/forgot-password" component={ForgotPwdForm} />
                    <Route path="/thank-you" component={ThankYou} />
                    <Route path="/" component={MainPage} />
                  </Switch>
                </BrowserRouter>
              </Suspense>
            ) : null}
          </>
        ) : null}
        {ShowWelcomePage[BRAND_DOMAIN] ? (
          <>
            {!consoleOpen ? (
              <Suspense fallback={<LoadingPage />}>
                <BrowserRouter>
                  <Switch>
                    <Redirect exact from="/" to="/welcome" />
                    <Route path="/welcome" component={WelcomeBonus} />
                    <Route path="/register" component={SignUp} />
                  </Switch>
                </BrowserRouter>
              </Suspense>
            ) : null}
          </>
        ) : null}
      </HelmetProvider>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Dialog
        open={consoleOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        className="dev-tools-msg-modal"
      >
        <DialogContent className="modal-content-ctn">
          <div className="dev-tools-warning-msg">
            {' '}
            Due to some inactivity or security reasons stop your website, please
            close the developer tool. Thank you for your support
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const useConsoleOpen = () => {
  const [consoleOpen, setConsoleOpen] = useState(false);

  useEffect(() => {
    let checkStatus;

    var element = new Image();
    Object.defineProperty(element, 'id', {
      get: function () {
        checkStatus = true;
        throw new Error('Dev tools checker');
      },
    });
    requestAnimationFrame(function check() {
      setTimeout(() => {
        checkStatus = false;
        // Don't delete this console statements
        // Uncomment fater fixing ios issues
        // if (process.env.REACT_APP_NODE_ENV !== 'development') {
        //   console.dir(element);
        //   console.clear();
        // }
        setConsoleOpen(checkStatus);
        requestAnimationFrame(check);
      }, 1000);
    });
  });

  return consoleOpen;
};

const mapStateToProps = (state: RootState) => {
  return {
    mailVerified: state.auth.mailVerified,
    prefersDark: state.common.prefersDark,
    loggedIn: state.auth.loggedIn,
    langData: state.common.langData,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchMailVerifiedSuccess: (mailVerified: boolean) =>
      dispatch(fetchMailVerifiedSuccess(mailVerified)),
    setDomainConfig: (config: DomainConfig) =>
      dispatch(setDomainConfig(config)),
    logout: () => dispatch(logout()),
    fetchContentConfig: () => dispatch(fetchContentConfig()),
    setLanguages: (languages: string[]) => dispatch(setLanguages(languages)),
    setLangSelected: (lang: string) => dispatch(setLangSelected(lang)),
    setLangData: (langData: any) => dispatch(setLangData(langData)),
    setAlertMsg: (alert: AlertDTO) => dispatch(setAlertMsg(alert)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
